<template>
    <div class="c-widget c-widget--video c-video" :class="[{ 'c-video--big': lastOddElement }, { 'o-container c-video--full': widgetIndex === undefined }]">
        <vue-plyr class="c-player c-player--video c-video__player" v-if="data.type === 'wordpress'">
            <video :src="data.video.url" preload="">
                <source :src="data.video.url" :type="data.video.mime_type">
            </video>
        </vue-plyr>
        <vue-plyr class="c-player c-player--video c-video__player c-video__player-yt" v-else-if="data.type === 'youtube'">
            <div class="plyr__video-embed">
                <iframe
                        :src="`https://www.youtube.com/embed/${this.videoID}?iv_load_policy=3&modestbranding=0&playsinline=1&showinfo=0&rel=0&enablejsapi=1&rel=0`"
                        allowfullscreen allowtransparency allow="autoplay">
                </iframe>
            </div>
        </vue-plyr>
        <vue-plyr class="c-player c-player--video c-video__player" v-else-if="data.type === 'vimeo'">
            <div class="plyr__video-embed">
                <iframe
                        :src="`https://player.vimeo.com/video/${this.videoID}?loop=false&byline=false&portrait=false&title=false&speed=true&transparent=0&gesture=media`"
                        allowfullscreen allowtransparency allow="autoplay">
                </iframe>
            </div>
        </vue-plyr>
        <vue-plyr class="c-player c-player--video c-video__player" v-else>
            <video :src="data.url" preload="">
                <source :src="data.url" type="video/mp4">
            </video>
        </vue-plyr>

        <p v-if="data.caption" class="c-video__caption">{{ data.caption }}</p>
    </div>
</template>

<script>
    import getVideoId from 'get-video-id'

    export default {
        name: 'Video',

        props: {
            data: Object,
            widgetIndex: Number,
            widgetGroupLength: Number
        },

        computed: {
            videoID () {
                return getVideoId(this.data.url).id
            },

            lastOddElement () {
                const groupLength = this.widgetGroupLength
                const widgetIndex = this.widgetIndex + 1

                console.log(groupLength);

                return ((widgetIndex === groupLength) && widgetIndex % 2) || typeof groupLength === 'undefined'
            }
        }
    }
</script>

<style lang="scss" src="@/styles/components/_player.scss"></style>
<style lang="scss" src="@/styles/components/_video.scss"></style>